import React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import { Wrap as StepsWrap, List as StepsList } from '../navigation/SecondaryNav';

const StepsNav = () => {
    const steps = useStaticQuery(graphql`
        {
            allContentfulSteps(sort: { order:ASC, fields: orderNumber }) {
                edges {
                    node {
                        title
                        link
                        orderNumber
                        icon {
                            file {
                                url
                            }
                        }
                        shortDescription
                    }
                }
            }
        }
    `);
    return (
            <StepsWrap>
                <div className="container">
                    <div className="menu-title">
                        <h2>THE RECIPE FOR INCREASING YOUR PROFIT</h2>
                        <h3>4 STEPS ANY COMPANY NEEDS TO GO THROUGH</h3>
                    </div>
                    <StepsList>
                        {steps.allContentfulSteps.edges.map(({ node }) => {
                            return (
                                <li key={node.title} >
                                    <Link to={`/steps/${node.link}/`} activeClassName="current">
                                        <div className="num">Step {node.orderNumber}</div>
                                        <img src={node.icon.file.url} alt={node.title} />
                                        <div>{node.title}</div>
                                    </Link>
                                </li>
                            );
                        })}
                    </StepsList>
                </div>
            </StepsWrap>
    );
}


export default StepsNav;

