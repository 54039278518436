import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { colors } from '../../constants/styles';


const classNameSlide = 'guideSlide'; 
const maxHeight = '5000';
const GuideToggle = styled.span`
    text-decoration: underline;
    cursor: pointer;
    font weight: 600;
    color: ${colors.orange};
`;
const GuideWrap = styled.div`      
    overflow: hidden;
    transition: max-height 1s ease-in-out;

    h3 {
        margin-top: 1.5rem;
    }

    // enter from
    &.${classNameSlide}-enter {
        max-height: 0;
    }

    // enter to
    &.${classNameSlide}-enter-active {
        max-height: ${maxHeight}px;
    }

    // exit from
    &.${classNameSlide}-exit {
        max-height: ${maxHeight}px;
    }

    // exit to 
    &.${classNameSlide}-exit-active {
        max-height: 0;
    }
`;

const StepsGuide = ({ data: { guideLinkText, guide } }) => {
    const [ guideOpen, setGuideOpen ] = useState(false);
    const guideOptions = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: node => {
                let url = node.data.target.fields.file['en-US'].url;
                return <img src={`https:${url}`} style={{minWidth: '500px'}} alt={node.data.target.fields.title}/>;
            }
        }
    };
    const guideContent = guide.content.json ? documentToReactComponents(guide.content.json, guideOptions) : null;

    const handleToggleCLick = () => {
        setGuideOpen(!guideOpen); 
    };
    return (
        <React.Fragment>
            <GuideToggle onClick={handleToggleCLick}>{guideLinkText}</GuideToggle>
            <CSSTransition
                in={guideOpen}
                classNames={classNameSlide}
                timeout={1000}
                unmountOnExit
            >
                {() => <GuideWrap id="guide-wrap">{guideContent}</GuideWrap>}
            </CSSTransition>
        </React.Fragment>
    );
}

export default StepsGuide;