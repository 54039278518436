import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import { colors, blueGradient } from '../../constants/styles';
import { CSSTransition } from 'react-transition-group';

const classNameSlide = 'question';
const maxHeight = '600';

const QuestionWrap = styled.section`
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    

    h1 {
        font-weight: bold;
        text-transform: none;
        color: ${colors.darkPurple};
        letter-spacing: 0;
        font-size: 1.2rem;
        margin-bottom: 1.6rem;
    }

    .btn {
        display: inline-block;
        margin-bottom: 0;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        white-space: nowrap;
        line-height: 1.42857143;
        user-select: none;
        background: ${colors.white};
        font-weight: bold;
        letter-spacing: 0.1em;
        font-size: 0.95rem;
        text-transform: uppercase;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 1.6rem;
        padding-left: 3.9rem;
        position: relative;
        border-radius: 2px;

        &:before {
            position: absolute;
            left: 1.4rem;
            top: 0.85rem;
            width: 28px;
            height: 28px;
            content: '';
        }
    }

    .btn-yes {
        margin-right: 0.65rem;
        border: 1px solid ${colors.green};
        color: ${colors.green};

        &:before {
            background: url(${props => props.yes.edges[0].node.publicURL}) center center no-repeat;
            background-size: 100%;
        }
    }

    .btn-no {
        border: 1px solid ${colors.orange2};
        color: ${colors.orange2};

        &:before {
            background: url(${props => props.no.edges[0].node.publicURL}) center center no-repeat;
            background-size: 100%;
        }
    }
`;

const StepOffer = styled.div`
    ${blueGradient}
    text-align: left;
    margin-top: 2rem;
    color: ${colors.white};
    padding: 1rem;

    overflow: hidden;
    transition: max-height 500ms ease-in-out;

    // enter from
    &.${classNameSlide}-enter {
        max-height: 0;
    }

    // enter to
    &.${classNameSlide}-enter-active {
        max-height: ${maxHeight}px;
    }

    // exit from
    &.${classNameSlide}-exit {
        max-height: ${maxHeight}px;
    }

    // exit to 
    &.${classNameSlide}-exit-active {
        max-height: 0;
    }

    h3 {
        color: ${colors.white};
        font-size: 1.2rem;
        margin-top: 1rem;
        margin-bottom: 0.7rem;
    }
`;

const Question = ({ data: {
    question,
    yesLink,
    noResult: {
        json
    }
} }) => {

    const { yes, no} = useStaticQuery(
        graphql`
            query {
                yes: allFile(filter: {name: {eq: "yes"}, extension: {eq: "png"}}) {
                    edges {
                        node {
                            publicURL
                        }
                    }
                } 
                no: allFile(filter: {name: {eq: "no"}, extension: {eq: "png"}}) {
                    edges {
                        node {
                            publicURL
                        }
                    }
                } 
            }
        `
    );

    const [ offerOpen, setOfferOpen ] = useState(false);

    const handleToggleOffer = () => {
        // TODO: add scroll to question offer
        setOfferOpen(true);
    }

    
    const offerContent = json ? documentToReactComponents(json) : null;
    return (
        <section className="question">
            <QuestionWrap className="container" yes={yes} no={no}>
                <h1>{question}</h1>
                <Link to={`${yesLink}/`} className="btn btn-yes">Yes</Link>
                <button className="btn btn-no" onClick={handleToggleOffer}>No</button>
            </QuestionWrap>
            <CSSTransition
                in={offerOpen}
                classNames={classNameSlide}
                timeout={500}
                unmountOnExit
            >
                {() => (
                    <StepOffer>
                        <div className="container">
                            {offerContent}
                        </div>  
                    </StepOffer>
                )}
            </CSSTransition>
        </section>
    );
}

export default Question;