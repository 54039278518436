import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styles';
import FormButton from './FormButton';

const Title = styled.h1`
margin-top: 1.2rem;
line-height: 1.1;
font-weight: bold;
text-transform: uppercase;
color: ${colors.darkGrey};
font-size: 1.4rem;
letter-spacing: 0.1em;
margin-bottom: 1.2rem;
text-align: center;

small {
    display: block;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: none;
    letter-spacing: 0;
}
`;

const FormWrap = styled.div`
text-align: center;
background: #eae9e9;
padding: 2.7rem 3rem 0 3rem;

.inputs {
    display: flex;
    justify-content: space-between;
    
    input {
        width: 49%;
        border: none;
        background: ${colors.white};
        font-weight: 300;
        font-size: 1.2rem;
        border: none;
        padding: 0.7rem 1rem 0.5rem;
    }

}
`;

const QuickForm = ({ name }) => {

    const [url, setUrl] = useState('');
    const [email, setEmail] = useState('');

    return (
        <section>
            <div className="container">
                <Title>
                    {name} <small>Complete the form below and get your result now!</small>
                </Title>
                <FormWrap>
                    <form method="post" name={name} data-netlify="true" action="/thank-you" data-netlify-honeypot="bot-field">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value={name} />
                        <div className="inputs">
                            <input 
                                type="text" 
                                name="url" 
                                onChange={(event) => setUrl(event.target.value)}
                                size="40" 
                                value={url}  
                                placeholder="Url" />
                            <input 
                                type="email" 
                                name="email" 
                                onChange={(event) => setEmail(event.target.value)}
                                size="40" 
                                value={email} 
                                placeholder="Email" />
                        </div>
                        <FormButton />
                    </form>
                </FormWrap>
            </div>
        </section>
    );
}

export default QuickForm;