import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import StepsNav from '../components/steps/StepsNav';
import Guide from '../components/steps/StepsGuide';
import Question from '../components/reusable/Question';
import QuickForm from '../components/forms/QuickForm';
import Funnel from '../components/reusable/Funnel';

export default ({
    data:  {
        allContentfulSteps : {
            edges: [
                {
                    node: {
                        title,
                        link,
                        content: {
                            json
                        },
                        guideLinkText,
                        guide,
                        question,
                        quickFormName
                    }
                }
            ]
        }
    }
}) => {
    
    const content = json ? documentToReactComponents(json) : null;
    return (
        <Layout>
            <Helmet>
                <meta charset="utf-8" />
                <title>{title}</title>
            </Helmet>
            <div>
                <StepsNav current={link} />
                <div className="container">
                    <h1>{title}</h1>
                    {content}
                    {guide && <Guide data={{ guideLinkText, guide }} />} 
                </div>
                {question && <Question data={question} />}
                {quickFormName && <QuickForm name={quickFormName} />}
            </div>
            {title === 'Profit Growth' && <Funnel />}
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!) {
        allContentfulSteps(filter: {link: {eq: $slug } }){
            edges {
                node {
                    title
                    link
                    content {
                        json
                    }
                    guideLinkText
                    guide {
                        content {
                            json
                        }
                    }
                    question {
                        question
                        yesLink
                        noResult {
                          json
                        }
                    }
                    quickFormName
                }
            }
        }
    }
`;