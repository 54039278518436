import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink, useStaticQuery, graphql  } from 'gatsby';
import { colors } from '../../constants/styles';
import { device } from '../../constants/mediaQueries';
import CtaButton from './CtaButton';
import PartnerLogos from './PartnerLogos';
 
const FunnelWrap = styled.section`
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    display: block;

    @media ${device.deviceXXS} {
        padding-top: 3rem;
        padding-bottom: 2.2rem;
    }

    .funnel {
        padding-top: 2rem;
        position: relative;
        padding-bottom: 1rem;

        > div {
            padding-bottom: 2rem;
            text-align: center;
            background: ${colors.blueTransparent};
            margin: auto;
            position: relative;
            font-size: 0.95rem;
            color: ${colors.darkGrey};
            margin-bottom: 2rem;
            padding-left: 0.9rem;
            padding-right: 0.9rem;

            @media ${device.deviceXXS} {
                padding-left: 2rem;
                padding-right: 2rem;
            }

            h2 {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.2rem;
                color: ${colors.blue};
                margin-bottom: 0.5rem;
                
                padding-top: 1.65rem;
                margin-top: 0;
            }

            &:after, &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                visibility: hidden;
                bottom: 0;     
            }

            &:after {
                right: 0;
                border-color: transparent transparent #ffffff transparent;

                @media ${device.deviceXXS} {
                    border-width: 0 0 480px 45px;
                    visibility: visible;
                }

                @media ${device.deviceS} {
                    border-width: 0 0 380px 45px;
                }

                @media ${device.deviceM} {
                    border-width: 0 0 260px 45px;
                }

                @media ${device.deviceL} {
                    border-width: 0 0 260px 85px;
                }
            }

            &:before {
                left: 0;
                border-color: transparent transparent transparent #ffffff;

                @media ${device.deviceXXS} {
                    border-width: 480px 0 0 45px;
                    visibility: visible;
                }

                @media ${device.deviceS} {
                    border-width: 380px 0 0 45px;
                }

                @media ${device.deviceM} {
                    border-width: 260px 0 0 45px;
                }

                @media ${device.deviceL} {
                    border-width: 260px 0 0 85px;
                }
            }


        }

        .lvl1 {
            @media ${device.deviceS} {
                width: 720px;
            }

            @media ${device.deviceM} {
                width: 940px;
            }

            @media ${device.deviceL} {
                width: 1140px;
            }
        }

        .lvl2 {
            @media ${device.deviceS} {
                width: 650px;
            }

            @media ${device.deviceM} {
                width: 850px;
            }

            @media ${device.deviceL} {
                width: 962px;
            }
        }

        .lvl3 {
            @media ${device.deviceS} {
                width: 575px;
            }

            @media ${device.deviceM} {
                width: 760px;
            }

            @media ${device.deviceL} {
                width: 780px;
            }
        }
    }

    .icons {
        display: flex;
        flex-wrap: wrap;
        > div {
            flex:1 1 auto;
            margin-bottom: 0.5rem;

            > div {
                line-height: 1.2em;
                padding-left: 3px;
                padding-right: 3px;
                font-size: 0.9rem;
                color: ${colors.blue};
                font-weight: 500;
                margin-left: 0.3rem;
                margin-right: 0.3rem;
            }
        }
    }
`;

const StyledLink = styled(GatsbyLink)`
    background: ${colors.lightGrey};
    border-radius: 0;
    border: none;
    border-bottom: 2px solid ${colors.lightBlue};
    box-shadow: 0px 5px 10px 0px ${colors.shadowed};
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;
    color: ${colors.lightBlue};
    padding: 0.7rem 2.1rem;
    position: absolute;
    left: 50%;
    bottom: -1.3rem;
    transform: translate(-50%, 0px);
    -webkit-transform: translate(-50%, 0px);
    -moz-transform: translate(-50%, 0px);
    -o-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
`;

const Funnel = () => {
    const funnelLevels = useStaticQuery(graphql`{
        levels: allContentfulFunnelLevel (sort: {order: ASC, fields: orderNumber} ) {
            edges {
                node {
                    title
                    description
                    orderNumber
                    icon {
                        file {
                            url
                        }
                        title
                    }
                    link
                }
            }
        }
    }`);
    return (
        <FunnelWrap>
            <div className="container">
                <h1 className="huge">Use the whole arsenal of internet marketing and a few more secret developments</h1>
            </div>
            <div className="container-fluid">
                <div className="funnel">
                    {funnelLevels.levels.edges.map(({ node }) => {
                        return (
                            <div className={`lvl${node.orderNumber}`} key={node.title}>
                                <h2>{node.title}</h2>
                                <p>{node.description}</p>
                                <div className="icons">
                                    {node.icon.map((icon) => {
                                        return (
                                            <div key={icon.title}>
                                                <img src={icon.file.url} alt={icon.title} />
                                                <div>{icon.title}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <StyledLink to={`${node.link}/`} className="btn">Read More</StyledLink>
                            </div>
                        );
                    })}
                </div>
                <CtaButton text="Get an individual strategy" arrowType="round" arrowText="Increase profit in 5 days" action={{modal: true, source: 'Funnel'}} />
                <PartnerLogos />
            </div>
        </FunnelWrap>
    );
}

export default Funnel;